import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row align-items-center bb-padding mt-5 mb-5" }
const _hoisted_3 = { class: "col-sm-12 col-md-7 align-self-start order-2 order-md-1" }
const _hoisted_4 = { class: "row text-center align-items-center" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "col-sm-12 col-md-5 order-1 order-md-2" }
const _hoisted_7 = { class: "bb-txt-sec-title mb-4 text-sm-center text-md-end" }
const _hoisted_8 = { class: "bb-txt-sec text-sm-center text-md-end mb-4" }
const _hoisted_9 = { class: "fw-bold" }


export default /*@__PURE__*/_defineComponent({
  __name: 'OurCustomersComponent',
  setup(__props) {

const logos = [
        { src: require("@/assets/images/logos/company/logo_edenred.svg"), alt: "Edenred" },
        { src: require("@/assets/images/logos/company/logo_allianz.svg"), alt: "Allianz" },
        { src: require("@/assets/images/logos/company/logo_unicredit.svg"), alt: "Unicredit" },
        { src: require("@/assets/images/logos/company/logo_beta80.svg"), alt: "Beta80" },
        { src: require("@/assets/images/logos/company/logo_horsa.svg"), alt: "Horsa" },
        { src: require("@/assets/images/logos/company/logo_banca_ifis.svg"), alt: "Banca Ifis" },
        { src: require("@/assets/images/logos/company/logo_vitaldent.svg"), alt: "Vitaldent" },
        { src: require("@/assets/images/logos/company/logo_esselunga.svg"), alt: "Esselunga" },
        { src: require("@/assets/images/logos/company/logo_axa.svg"), alt: "Axa" },
        { src: require("@/assets/images/logos/company/logo_bip.svg"), alt: "Bip" },
        { src: require("@/assets/images/logos/company/logo_sb-italia.svg"), alt: "SB Italia" },
        { src: require("@/assets/images/logos/company/logo_soprasteria.svg"), alt: "Soprasteria" },
        { src: require("@/assets/images/logos/company/logo_dale.svg"), alt: "Dale" },
        { src: require("@/assets/images/logos/company/logo_dontouch.svg"), alt: "Dountouch" },
        { src: require("@/assets/images/logos/company/logo_s2e.svg"), alt: "S2E" },
        // Aggiungi qui tutti i loghi
      ]

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(logos, (logo, index) => {
            return _createElementVNode("div", {
              class: "col-4 col-sm-3 my-3 pb-sm-1 pb-md-3 align-content-center flex-wrap justify-content-center bb-size",
              key: index
            }, [
              _createElementVNode("img", {
                src: logo.src,
                alt: logo.alt,
                class: "img-fluid"
              }, null, 8, _hoisted_5)
            ])
          }), 64))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('OUR_CUSTOMERS_CLIENTS')), 1),
        _createElementVNode("p", _hoisted_8, [
          _createTextVNode(_toDisplayString(_ctx.$t('OUR_CUSTOMERS_TEXT_1')), 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(_toDisplayString(_ctx.$t('OUR_CUSTOMERS_TEXT_2')), 1),
          _createElementVNode("strong", _hoisted_9, _toDisplayString(_ctx.$t('OUR_CUSTOMERS_TEXT_3')), 1)
        ])
      ])
    ])
  ]))
}
}

})