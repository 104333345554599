<template>
<div class="container pb-5 pt-5">
  <div class="row pt-5">
    <div class="col-sm-12 bb-padding mt-5 mb-5">
      <h2 class="text-center bb-txt-sec-name mb-4">{{ $t('WHAT_WE_DO_TITLE') }}</h2>
      <h3 class="text-center bb-txt-sec-title mb-4" v-html="$t('WHAT_WE_DO_SUBTITLE')"></h3>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 bb-padding">
      <div class="image-wrapper">
        <img :src="images[currentIndex]" class="img-fluid" alt="What we do" />
        <div class="carousel-controls">
          <button class="carousel-btn prev-btn" name="previus" id="prev" aria-label="previus" role="button" @click="prevSlide">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </button>
          <button class="carousel-btn next-btn" name="next" id="next" aria-label="next" role="button" @click="nextSlide">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
      <!-- Sezione puntini -->
      <div class="carousel-indicators">
        <span
          v-for="(image, index) in images"
          :key="index"
          class="dot"
          :class="{ active: index === currentIndex }"
          @click="goToSlide(index)">
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 mt-5 p-0">
      <h3 class="text-center bb-txt-sec-title pb-0 pb-lg-5">{{ $t('WHAT_WE_DO_SERVICES') }}</h3>
    </div>
  </div>

  <div class="row pt-5 pb-0 pb-sm-5">
    <div class="col-sm-5 align-self-center bb-padding-dx">
      <h4 class="text-left bb-txt-sec-subtitle pb-3 bb-txt-margin-custom">{{ $t('WHAT_WE_DO_PM') }}</h4>
      <p class="text-left bb-txt-sec bb-txt-margin-custom">{{ $t('WHAT_WE_DO_PROJECT') }}</p>
    </div>
    <div class="col-sm-7 p-0 align-self-center bb-p-cell">
        <img src="../../assets/images/Raggruppa-pm.svg" class="img-fluid float-end" alt="Definition">
    </div>
  </div>
  <div class="row pt-5">
    <div class="col-sm-7 p-0 order-2 order-md-1 align-self-center bb-p-cell-r">
        <img src="../../assets/images/Raggruppa-o.svg" class="img-fluid" alt="Definition">
    </div>
    <div class="pt-5"></div>
    <div class="col-sm-5 align-self-center p-0 order-1 order-md-2 bb-padding-lx">
      <h4 class="text-end bb-txt-sec-subtitle pb-3 bb-txt-margin-custom-right">{{ $t('WHAT_WE_DO_OUTSOURCING') }}</h4>
      <p class="text-end bb-txt-sec bb-txt-margin-custom-right">{{ $t('WHAT_WE_DO_EST') }}</p>
    </div>
  </div>
</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const images = ref([
  require('../../assets/images/what-we-do-carousel-1.png'),
  require('../../assets/images/what-we-do-carousel-2.png'),
  require('../../assets/images/what-we-do-carousel-3.png'),
]);

const currentIndex = ref(0);

const prevSlide = () => {
  currentIndex.value = (currentIndex.value - 1 + images.value.length) % images.value.length;
};

const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % images.value.length;
};

// Funzione per andare direttamente a una slide specifica
const goToSlide = (index: number) => {
  currentIndex.value = index;
};
</script>

<style scoped>

.bb-image {
  width: 100%;
  max-width: 100%;
}

.image-wrapper {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-wrapper img {
  width: 80%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.carousel-controls {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.carousel-btn {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.carousel-btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
  transform: scale(1.1);
}

.carousel-btn i {
  font-size: 50px;
  color: #ffffff;
}

/* Stile per i puntini */
.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: rgba(255, 255, 255, 0.9);
}

.svg-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px; /* Aumenta lo spazio tra gli SVG su desktop */
}

.image {
  width: 50%; /* Larghezza per desktop */
  max-width: 200px; /* Limita le dimensioni massime sugli schermi più grandi */
}

/* Media query per schermi più piccoli */
@media (max-width: 750px) {
  .carousel-controls {
    padding: 0 5px;
  }
  .carousel-btn {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .carousel-btn i {
    font-size: 30px;
  }
  .carousel-indicators {
    margin-top: 5%;
  }
  .col-md-7 .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .svg-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Spazio minore tra SVG su mobile */
  }
  .image {
    width: 45%; /* Affianca gli SVG su mobile */
    max-width: none;
  }
}

@media (min-width: 2800px) {

  .img-fluid {
    width: 80%;
    height: auto;
  }

  .carousel-btn{
    width: 90px;
    height: 90px;
  }

  .dot {
    width: 25px;
    height: 25px;
    margin: 10px 18px;
  }

  .bb-padding-lx{
    padding-left:0rem !important;
    padding-right:30rem !important;
  }

  .bb-padding-dx{
    padding-left:30rem !important;
    padding-right:0rem !important;
  }


}

@media only screen and (min-width: 2300px) and (max-width:2799px){

  .img-fluid {
    width: 80%;
    height: auto;
  }

  .carousel-btn{
    width: 60px;
    height: 60px;
  }

  .dot {
    width: 15px;
    height: 15px;
    margin: 10px 15px;
  }
  .bb-padding-lx{
    padding-left:0rem !important;
    padding-right:20rem !important;
  }

  .bb-padding-dx{
    padding-left:20rem !important;
    padding-right:0rem !important;
  }


}

@media only screen and (min-width:1441px) and (max-width:2299px){

.bb-padding-lx{
  padding-left:10rem !important;
  padding-right:7rem !important;
}

.bb-padding-dx{
  padding-left:7rem !important;
  padding-right:10rem !important;
}

}

@media only screen and (min-width:1025px) and (max-width:1440px) {

.bb-padding-lx{
  padding-left:4rem !important;
  padding-right:2rem !important;
}

.bb-padding-dx{
  padding-left:2rem !important;
  padding-right:4rem !important;
}

}

@media only screen and (min-width:769px) and (max-width:1024px) {

  .bb-padding-lx{
  padding-left:2rem !important;
  padding-right:3rem !important;
}

.bb-padding-dx{
  padding-left:3rem !important;
  padding-right:2rem !important;
}

.bb-padding{
  padding-left:4rem;
  padding-right:4rem;
}

}

@media only screen and (min-width:426px) and (max-width:768px) {

.bb-padding-lx{
  padding-left:1rem !important;
  padding-right:2rem !important;
}

.bb-padding-dx{
  padding-left:2rem !important;
  padding-right:1rem !important;
}

.bb-padding{
  padding-left:4rem !important;
  padding-right:4rem !important;
}
}

@media only screen and (max-width: 425px) {
  .image-wrapper img {
    width: 100%;
  }
  .bb-p-cell{
    padding-left: 2rem !important;
    padding-top: 2rem !important;
  }
  .bb-p-cell-r{
    padding-right: 2rem !important;
    padding-top: 2rem !important;
  }

  .bb-padding-lx{
  padding-left:1rem !important;
  padding-right:1rem !important;
}

.bb-padding-dx{
  padding-left:1rem !important;
  padding-right:1rem !important;
}
}


</style>
