<template>
  <div class="container pb-5 pt-3">
    <div class="row">
      <div class="col-sm-12 pt-5 pb-5">
        <p class="text-center bb-txt-sec-title">{{ $t('TECHNOLOGIES') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="lc-block">
          <div id="carouselLogos" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner px-1">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/java.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/vuejs.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/liferay.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3 " src="@/assets/images/logos/tech/plsql.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/oracle.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/qlik.svg" alt="">
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row">
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/powerbi.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/hadoop.svg" alt="">
                  </div>

                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/groovy.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2  d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/etl.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2  d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/aws.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/python.svg" alt="">
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row">
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/docker.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/flutter.svg" alt="">
                  </div>

                  <div class="col-6 col-md-4 col-lg-2 d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/Kubernetes.svg" alt="">
                  </div>
                  <div class="col-6 col-md-4 col-lg-2  d-flex align-content-center flex-wrap justify-content-center bb-height">
                    <img class="img-fluid px-3" src="@/assets/images/logos/tech/mongoDB.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
            <ol class="carousel-indicators list-unstyled position-relative">
              <li data-bs-target="#carouselLogos" data-bs-slide-to="0" class="active bg-dark carousel-control-prev-icon"></li>
              <li data-bs-target="#carouselLogos" data-bs-slide-to="1" class="bg-dark"></li>
              <li data-bs-target="#carouselLogos" data-bs-slide-to="2" class="bg-dark"></li>
            </ol>
            <div class="w-100 px-3 text-center">
              <a class="carousel-control-prev position-relative d-inline" href="#carouselLogos" data-bs-slide="prev" aria-label="prev">
              </a>
              <a class="carousel-control-next position-relative d-inline" href="#carouselLogos" data-bs-slide="next" aria-label="next">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup lang="ts">
  </script>
  
  <style scoped>

  .carousel-item-next, .carousel-item-prev, .carousel-item.active, .carousel-item, .bb-height {
      min-height: 170px;
  }

  .bg-dark {
    background-color: rgb(249 249 249) !important;
  }

  @media (min-width: 2800px) {
    .carousel-item-next, .carousel-item-prev, .carousel-item.active, .carousel-item, .bb-height {
      min-height: 300px;
    }

    .img-fluid {
      width: 75%;
      height: auto;
    }

  }

  @media only screen and (min-width: 2300px) and (max-width:2799px){
    .carousel-item-next, .carousel-item-prev, .carousel-item.active, .carousel-item, .bb-height {
      min-height: 250px;
    }

    .img-fluid {
      width: 75%;
      height: auto;
    }
  }

  </style>
  