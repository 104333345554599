import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-xxl" }
const _hoisted_2 = { class: "row bb-backgroud bb-padding-top bb-padding" }
const _hoisted_3 = { class: "col-sm-12 mb-5" }
const _hoisted_4 = { class: "bb-txt-sec-name mb-4" }
const _hoisted_5 = { class: "bb-txt-sec-title mb-4" }
const _hoisted_6 = { class: "row bb-padding" }
const _hoisted_7 = {
  class: "col-sm-12 py-3 mb-5",
  style: {"background":"white"}
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = { class: "mb-3" }
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = ["innerHTML"]
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = ["innerHTML"]
const _hoisted_41 = ["innerHTML"]
const _hoisted_42 = ["innerHTML"]
const _hoisted_43 = ["innerHTML"]
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = ["innerHTML"]
const _hoisted_47 = ["innerHTML"]
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = ["innerHTML"]
const _hoisted_50 = ["innerHTML"]
const _hoisted_51 = ["innerHTML"]
const _hoisted_52 = ["innerHTML"]
const _hoisted_53 = ["innerHTML"]
const _hoisted_54 = ["innerHTML"]
const _hoisted_55 = ["innerHTML"]
const _hoisted_56 = { type: "a" }
const _hoisted_57 = ["innerHTML"]
const _hoisted_58 = ["innerHTML"]
const _hoisted_59 = ["innerHTML"]
const _hoisted_60 = ["innerHTML"]
const _hoisted_61 = ["innerHTML"]
const _hoisted_62 = ["innerHTML"]
const _hoisted_63 = ["innerHTML"]
const _hoisted_64 = ["innerHTML"]
const _hoisted_65 = ["innerHTML"]
const _hoisted_66 = ["innerHTML"]
const _hoisted_67 = ["innerHTML"]
const _hoisted_68 = ["innerHTML"]
const _hoisted_69 = ["innerHTML"]
const _hoisted_70 = ["innerHTML"]
const _hoisted_71 = ["innerHTML"]
const _hoisted_72 = ["innerHTML"]
const _hoisted_73 = ["innerHTML"]
const _hoisted_74 = ["innerHTML"]
const _hoisted_75 = ["innerHTML"]
const _hoisted_76 = ["innerHTML"]
const _hoisted_77 = ["innerHTML"]
const _hoisted_78 = ["innerHTML"]
const _hoisted_79 = ["innerHTML"]

import { onMounted } from 'vue';
import NavbarComponent from '../elements/NavbarComponent.vue';
import FooterComponent from "@/components/layout/FooterComponent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'DataProtectionPolicyComponent',
  setup(__props) {

onMounted(() =>{
 window.scrollTo(0,0);

});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavbarComponent),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('PD_2')), 1),
          _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t('PD_1')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_3')
          }, null, 8, _hoisted_8),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_4')
          }, null, 8, _hoisted_9),
          _createElementVNode("p", {
            class: "bb-info-txt",
            innerHTML: _ctx.$t('PD_5')
          }, null, 8, _hoisted_10),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_6')
              }, null, 8, _hoisted_11),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_7')
                  }, null, 8, _hoisted_12)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_8')
                  }, null, 8, _hoisted_13)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_9')
                  }, null, 8, _hoisted_14)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_10')
                  }, null, 8, _hoisted_15)
                ])
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_11')
              }, null, 8, _hoisted_16),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_12')
                  }, null, 8, _hoisted_17)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_13')
                  }, null, 8, _hoisted_18),
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, [
                      _createElementVNode("p", {
                        class: "bb-info-txt",
                        innerHTML: _ctx.$t('PD_14')
                      }, null, 8, _hoisted_19)
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("p", {
                        class: "bb-info-txt",
                        innerHTML: _ctx.$t('PD_15')
                      }, null, 8, _hoisted_20)
                    ])
                  ]),
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_16')
                  }, null, 8, _hoisted_21)
                ])
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_17')
              }, null, 8, _hoisted_22),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_18')
                  }, null, 8, _hoisted_23)
                ])
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_19')
              }, null, 8, _hoisted_24),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_20')
                  }, null, 8, _hoisted_25)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_21')
                  }, null, 8, _hoisted_26)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_22')
                  }, null, 8, _hoisted_27)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_23')
                  }, null, 8, _hoisted_28)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_24')
                  }, null, 8, _hoisted_29)
                ])
              ])
            ])
          ]),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_25')
          }, null, 8, _hoisted_30),
          _createElementVNode("p", {
            class: "bb-info-txt",
            innerHTML: _ctx.$t('PD_26')
          }, null, 8, _hoisted_31),
          _createElementVNode("ul", _hoisted_32, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_27')
              }, null, 8, _hoisted_33)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_28')
              }, null, 8, _hoisted_34)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_29')
              }, null, 8, _hoisted_35)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_30')
              }, null, 8, _hoisted_36)
            ])
          ]),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_31')
          }, null, 8, _hoisted_37),
          _createElementVNode("p", {
            class: "bb-info-txt",
            innerHTML: _ctx.$t('PD_32')
          }, null, 8, _hoisted_38),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_33')
              }, null, 8, _hoisted_39)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_34')
              }, null, 8, _hoisted_40)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_35')
              }, null, 8, _hoisted_41)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_36')
              }, null, 8, _hoisted_42)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_37')
              }, null, 8, _hoisted_43)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_38')
              }, null, 8, _hoisted_44)
            ])
          ]),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_39')
          }, null, 8, _hoisted_45),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_40')
          }, null, 8, _hoisted_46),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_41')
          }, null, 8, _hoisted_47),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_42')
          }, null, 8, _hoisted_48),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_43')
          }, null, 8, _hoisted_49),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_44')
          }, null, 8, _hoisted_50),
          _createElementVNode("p", {
            class: "bb-info-txt",
            innerHTML: _ctx.$t('PD_45')
          }, null, 8, _hoisted_51),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_46')
              }, null, 8, _hoisted_52)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_47')
              }, null, 8, _hoisted_53)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_48')
              }, null, 8, _hoisted_54)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_49')
              }, null, 8, _hoisted_55),
              _createElementVNode("ol", _hoisted_56, [
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_50')
                  }, null, 8, _hoisted_57)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_51')
                  }, null, 8, _hoisted_58)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_52')
                  }, null, 8, _hoisted_59)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", {
                    class: "bb-info-txt",
                    innerHTML: _ctx.$t('PD_53')
                  }, null, 8, _hoisted_60)
                ])
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_54')
              }, null, 8, _hoisted_61)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_55')
              }, null, 8, _hoisted_62)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_56')
              }, null, 8, _hoisted_63)
            ])
          ]),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_57', { email: '<a href=\'mailto:info@beasy4biz.com\'>info@beasy4biz.com</a>' })
          }, null, 8, _hoisted_64),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_58')
          }, null, 8, _hoisted_65),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_59')
          }, null, 8, _hoisted_66),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_60')
          }, null, 8, _hoisted_67),
          _createElementVNode("p", {
            class: "bb-info-txt",
            innerHTML: _ctx.$t('PD_61')
          }, null, 8, _hoisted_68),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_62')
              }, null, 8, _hoisted_69)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_63')
              }, null, 8, _hoisted_70)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('PD_64')
              }, null, 8, _hoisted_71)
            ])
          ]),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_65')
          }, null, 8, _hoisted_72),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_66')
          }, null, 8, _hoisted_73),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_67')
          }, null, 8, _hoisted_74),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_68')
          }, null, 8, _hoisted_75),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_69')
          }, null, 8, _hoisted_76),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_70')
          }, null, 8, _hoisted_77),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('PD_71', { email: '<a href=\'mailto:dpo@beasy4biz.com\'>dpo@beasy4biz.com</a>' })
          }, null, 8, _hoisted_78),
          _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('PD_72')
          }, null, 8, _hoisted_79)
        ])
      ])
    ]),
    _createVNode(FooterComponent)
  ], 64))
}
}

})