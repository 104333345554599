import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/icons/sede icon.svg'


const _hoisted_1 = { class: "container pt-5" }
const _hoisted_2 = { class: "row pt-5" }
const _hoisted_3 = { class: "col-sm-12 align-self-left mb-5 mt-5 bb-padding" }
const _hoisted_4 = { class: "bb-txt-sec-name mb-4" }
const _hoisted_5 = { class: "bb-txt-sec-title mb-4" }
const _hoisted_6 = { class: "bb-txt-sec" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12 col-lg-6 bb-padding-box-reverse" }
const _hoisted_9 = { class: "card bg-info" }
const _hoisted_10 = { class: "bb-form-title mb-4" }
const _hoisted_11 = { class: "form-group mb-3" }
const _hoisted_12 = {
  for: "name",
  class: "bb-form-txt"
}
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "form-group mb-3" }
const _hoisted_15 = {
  for: "email",
  class: "bb-form-txt"
}
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = { class: "form-group mb-3" }
const _hoisted_18 = {
  for: "note",
  class: "bb-form-txt"
}
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = { class: "form-check mb-4 mt-4" }
const _hoisted_21 = {
  class: "form-check-label",
  for: "privacyCheck"
}
const _hoisted_22 = { class: "text-center mb-4 mt-4" }
const _hoisted_23 = ["disabled"]
const _hoisted_24 = {
  class: "alert alert-success text-center",
  role: "alert"
}
const _hoisted_25 = {
  class: "alert alert-danger text-center",
  role: "alert"
}
const _hoisted_26 = { class: "row pt-5" }
const _hoisted_27 = { class: "col-sm-12 mb-5 mt-3 bb-padding" }
const _hoisted_28 = { class: "bb-txt-sec-subtitle mb-4" }
const _hoisted_29 = { class: "d-flex align-items-start mt-4" }
const _hoisted_30 = { class: "bb-address-txt-title mb-3 mt-1" }
const _hoisted_31 = { class: "bb-address-txt mb-0" }
const _hoisted_32 = { class: "bb-address-txt mb-0" }

import { ref, computed } from 'vue';
import { useEmailServiceBeasy } from '@/services/composables/useEmailServiceBeasy';
import { useFirestore } from '@/services/api/useFirestore';
import { ContactUsForm } from '@/services/models/contactUsFormData';
import { Domain } from '@/services/models/domain';
import { Outcome } from '@/services/models/outcome';
import DOMPurify from 'dompurify';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactsComponent',
  setup(__props) {

const formData = ref({
  name: '',
  email: '',
  note: '',
  privacyAccepted: false
});

const checkMailSuccess = ref(false);
const checkMailError = ref(false);
const emailOutcome = ref(Outcome.KO);
const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const isFormValid = computed(() =>
      formData.value.name.trim().length !== 0 &&
      formData.value.email.trim().length !== 0 &&
      regexEmail.test(formData.value.email) &&
      formData.value.note && 
        formData.value.note.trim().length !== 0 && 
        formData.value.note.length <= 150 &&
      formData.value.privacyAccepted
    );

const sanitizeInput = (input: string): string => {
  return DOMPurify.sanitize(input);
};

const submitForm = () => {
  const {sendEmailToBeasy, sendEmailToCustomer} = useEmailServiceBeasy();
  const { registerNewEmail } = useFirestore();

  checkMailError.value = false;
  checkMailSuccess.value = false;

  // sanifico di dati inseriti nel form
  const sanitizedData = {
    name: sanitizeInput(formData.value.name),
    email: sanitizeInput(formData.value.email),
    note: sanitizeInput(formData.value.note),
  };

  const dataToSend = new ContactUsForm(sanitizedData.name, '', sanitizedData.email, emailOutcome.value, sanitizedData.note);
  
  sendEmailToBeasy(dataToSend)
  .then(() => {
    sendEmailToCustomer(dataToSend)
    .then(() =>{
      checkMailSuccess.value =true;
      dataToSend.emailOutcome = Outcome.OK;

      // Reset form data after all it's done
      formData.value.name = '';
      formData.value.email = '';
      formData.value.note = '';
      formData.value.privacyAccepted = false;
    }).catch(mailError);
  })
  .catch(mailError)
  .finally(() => registerNewEmail(dataToSend, Domain.BEASY_4_BIZ));
};

function mailError() {
  console.log(checkMailError.value);
  checkMailError.value = true;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('CONTACTS_TITLE')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('CONTACTS_FORM')), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('CONTACTS_FORM_INF')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-sm-12 col-lg-6 bb-padding-box" }, [
        _createElementVNode("div", { class: "map-container ratio ratio-16x9" }, [
          _createElementVNode("iframe", {
            src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2760.595362111384!2d8.94979751555781!3d46.00597897911232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784d9a8edc4e2af%3A0xa1d91a72e34b3ed8!2sVia%20Serafino%20Balestra%2010%2C%206900%20Lugano%2C%20Svizzera!5e0!3m2!1sit!2sit!4v1698165248000!5m2!1sit!2sit",
            allowfullscreen: "",
            loading: "lazy",
            class: "rounded",
            title: "map"
          })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('CONTACTS_INSERT_INFO')), 1),
          _createElementVNode("form", {
            id: "form",
            onSubmit: _withModifiers(submitForm, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('CONTACTS_FULLNAME')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                id: "name",
                placeholder: _ctx.$t('PLACEHOLDER_NAME_SURNAME'),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.name) = $event))
              }, null, 8, _hoisted_13), [
                [_vModelText, formData.value.name]
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('CONTACTS_EMAIL')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                class: "form-control",
                id: "email",
                placeholder: _ctx.$t('PLACEHOLDER_EMAIL'),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.email) = $event))
              }, null, 8, _hoisted_16), [
                [_vModelText, formData.value.email]
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('CONTACTS_NOTE')), 1),
              _withDirectives(_createElementVNode("textarea", {
                class: "form-control",
                id: "note",
                rows: "3",
                placeholder: _ctx.$t('PLACEHOLDER_NOTE'),
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.note) = $event))
              }, null, 8, _hoisted_19), [
                [_vModelText, formData.value.note]
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                class: "form-check-input",
                id: "privacyCheck",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.privacyAccepted) = $event))
              }, null, 512), [
                [_vModelCheckbox, formData.value.privacyAccepted]
              ]),
              _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('CONTACTS_CONFIRM_PRIVACY')), 1)
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("button", {
                type: "submit",
                class: "bb-btn-submit",
                name: "submit",
                id: "submit",
                "aria-label": "submit",
                disabled: !isFormValid.value
              }, _toDisplayString(_ctx.$t('CONTACTS_CONTACT')), 9, _hoisted_23)
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_24, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('TXT_MAIL_SUCCESS_1')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('TXT_MAIL_SUCCESS_2')), 1)
            ], 512), [
              [_vShow, checkMailSuccess.value]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_25, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('TXT_MAIL_ERROR_1')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('TXT_MAIL_ERROR_2')), 1)
            ], 512), [
              [_vShow, checkMailError.value]
            ])
          ], 32)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _cache[6] || (_cache[6] = _createElementVNode("hr", { class: "bb-hr" }, null, -1)),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t('CONTACTS_OUR_HEADQUARTERS')), 1),
        _createElementVNode("div", _hoisted_29, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "me-4 me-sm-5 bb-m" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "icona mappa",
              class: "icon"
            })
          ], -1)),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('CONTACTS_LEGAL_HEADQUARTERS')), 1),
            _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t('CONTACTS_ADDRESS')), 1),
            _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$t('CONTACTS_NATION')), 1)
          ])
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("hr", { class: "bb-hr mb-5" }, null, -1))
    ])
  ]))
}
}

})