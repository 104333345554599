<template>
    <NavbarBlackshipComponent />
    <div class="container-xxl">
        <div class="row bb-backgroud bb-padding-top bb-padding">
            <div class="col-sm-12 mb-5">
                <h3 class="bb-txt-sec-name mb-4">{{ $t('PD_2') }}</h3>
                <h4 class="bb-txt-sec-title mb-4">{{ $t('PD_1') }}</h4>
            </div>
        </div>
        <div class="row bb-padding">
            <div class="col-sm-12 py-3 mb-5" style="background: white;">
                <p class="bb-info-txt mb-3" v-html="$t('PD_3')"></p>

                <p class="bb-info-subtitle" v-html="$t('PD_4')"></p>

                <p class="bb-info-txt" v-html="$t('PD_5')"></p>

                <ul>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_6')"></p>
                        <ul>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_7')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_8')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_9')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_10')"></p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_11')"></p>
                        <ul>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_12')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_13')"></p>
                                <ul>
                                    <li>
                                        <p class="bb-info-txt" v-html="$t('PD_14')"></p>
                                    </li>
                                    <li>
                                        <p class="bb-info-txt" v-html="$t('PD_15')"></p>
                                    </li>
                                </ul>
                                <p class="bb-info-txt" v-html="$t('PD_16')"></p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_17')"></p>
                        <ul>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_18')"></p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_19')"> </p>
                        <ul>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_20')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_21')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_22')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_23')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_24')"></p>
                            </li>
                        </ul>
                    </li>
                </ul>

                <p class="bb-info-subtitle" v-html="$t('PD_25')"></p>

                <p class="bb-info-txt" v-html="$t('PD_26')"></p>

                <ul class="mb-3">
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_27')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_28')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_29')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_30')"></p>
                    </li>
                </ul>


                <p class="bb-info-subtitle" v-html="$t('PD_31')"></p>
                <p class="bb-info-txt" v-html="$t('PD_32')"></p>

                <ul>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_33')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_34')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_35')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_36')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_37')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_38')"></p>
                    </li>
                </ul>
                <p class="bb-info-txt mb-3" v-html="$t('PD_39')"></p>

                <p class="bb-info-subtitle" v-html="$t('PD_40')"></p>
                <p class="bb-info-txt mb-3" v-html="$t('PD_41')"></p>

                <p class="bb-info-subtitle" v-html="$t('PD_42')"></p>
                <p class="bb-info-txt mb-3" v-html="$t('PD_43')"></p>

                <p class="bb-info-subtitle" v-html="$t('PD_44')"></p>
                <p class="bb-info-txt" v-html="$t('PD_45')"></p>
                <ul>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_46')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_47')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_48')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_49')"></p>
                        <ol type="a">
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_50')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_51')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_52')"></p>
                            </li>
                            <li>
                                <p class="bb-info-txt" v-html="$t('PD_53')"></p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_54')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_55')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_56')"></p>
                    </li>
                </ul>
                <p class="bb-info-txt mb-3" v-html="$t('PD_57', { email: '<a href=\'mailto:info@beasy4biz.com\'>info@beasy4biz.com</a>' })"></p>
                
                <p class="bb-info-subtitle" v-html="$t('PD_58')"></p>
                <p class="bb-info-txt mb-3" v-html="$t('PD_59')"></p>

                <p class="bb-info-subtitle" v-html="$t('PD_60')"></p>
                <p class="bb-info-txt" v-html="$t('PD_61')"></p>
                <ul>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_62')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_63')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('PD_64')"></p>
                    </li>
                </ul>
                <p class="bb-info-txt mb-3" v-html="$t('PD_65')"></p>

                <p class="bb-info-subtitle" v-html="$t('PD_66')"></p>
                <p class="bb-info-txt mb-3" v-html="$t('PD_67')"></p>

                <p class="bb-info-subtitle" v-html="$t('PD_68')"></p>
                <p class="bb-info-txt mb-3" v-html="$t('PD_69')"></p>

                <p class="bb-info-subtitle" v-html="$t('PD_70')"></p>
                <p class="bb-info-txt mb-3" v-html="$t('PD_71', { email: '<a href=\'mailto:dpo@beasy4biz.com\'>dpo@beasy4biz.com</a>' })"></p>
                <hr>
                <p class="bb-info-subtitle" v-html="$t('PD_72')"></p>
            </div>
        </div>
    </div>
<FooterBlackshipComponent />

</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import NavbarBlackshipComponent from "@/components/elements/NavbarBlackshipComponent.vue";
import FooterBlackshipComponent from "@/components/layout/FooterBlackshipComponent.vue";

onMounted(() =>{
 window.scrollTo(0,0);

});

</script>

<style scoped lang="scss">
.bb-padding-top{
    padding-top: 10rem;
}

</style>