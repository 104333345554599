<template>
    <div class="container">
        <div class="row align-items-center bb-padding mt-5 mb-5">
            <!-- LOGHI -->
            <!-- <div class="col-sm-12 col-md-7 order-sm-1 order-md-2"> -->
            <div class="col-sm-12 col-md-7 align-self-start order-2 order-md-1">
                <div class="row text-center align-items-center">
                    <div class="col-4 col-sm-3 my-3 pb-sm-1 pb-md-3 align-content-center flex-wrap justify-content-center bb-size" v-for="(logo, index) in logos" :key="index">
                        <img :src="logo.src" :alt="logo.alt" class="img-fluid" />
                    </div>
                </div>
            </div>
            
    
            <!-- TESTO SULLA DESTRA-->
            <div class="col-sm-12 col-md-5 order-1 order-md-2">
                <p class="bb-txt-sec-title mb-4 text-sm-center text-md-end">{{ $t('OUR_CUSTOMERS_CLIENTS') }}</p>
                <p class="bb-txt-sec text-sm-center text-md-end mb-4">{{ $t('OUR_CUSTOMERS_TEXT_1') }}<br>{{ $t('OUR_CUSTOMERS_TEXT_2') }}<strong class="fw-bold">{{ $t('OUR_CUSTOMERS_TEXT_3') }}</strong></p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const logos = [
        { src: require("@/assets/images/logos/company/logo_edenred.svg"), alt: "Edenred" },
        { src: require("@/assets/images/logos/company/logo_allianz.svg"), alt: "Allianz" },
        { src: require("@/assets/images/logos/company/logo_unicredit.svg"), alt: "Unicredit" },
        { src: require("@/assets/images/logos/company/logo_beta80.svg"), alt: "Beta80" },
        { src: require("@/assets/images/logos/company/logo_horsa.svg"), alt: "Horsa" },
        { src: require("@/assets/images/logos/company/logo_banca_ifis.svg"), alt: "Banca Ifis" },
        { src: require("@/assets/images/logos/company/logo_vitaldent.svg"), alt: "Vitaldent" },
        { src: require("@/assets/images/logos/company/logo_esselunga.svg"), alt: "Esselunga" },
        { src: require("@/assets/images/logos/company/logo_axa.svg"), alt: "Axa" },
        { src: require("@/assets/images/logos/company/logo_bip.svg"), alt: "Bip" },
        { src: require("@/assets/images/logos/company/logo_sb-italia.svg"), alt: "SB Italia" },
        { src: require("@/assets/images/logos/company/logo_soprasteria.svg"), alt: "Soprasteria" },
        { src: require("@/assets/images/logos/company/logo_dale.svg"), alt: "Dale" },
        { src: require("@/assets/images/logos/company/logo_dontouch.svg"), alt: "Dountouch" },
        { src: require("@/assets/images/logos/company/logo_s2e.svg"), alt: "S2E" },
        // Aggiungi qui tutti i loghi
      ]
</script>

<style scoped lang="scss">

.bb-size {
    min-height: 100px;
}


@media (min-width: 2800px) {
    .bb-size {
        min-height: 225px;
    }

    .img-fluid {
        height: auto;
        width: 70%;
    }
    
}

@media only screen and (min-width: 2300px) and (max-width:2799px){
    .bb-size {
        min-height: 150px;
    }

    .img-fluid {
        height: auto;
        width: 70%;
    }
}
</style>