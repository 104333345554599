import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mr-3 ml-3" }
const _hoisted_2 = ["value"]

import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageComponent',
  setup(__props) {

const store = useStore();
const { locale } = useI18n();

const languages = {
    en: 'ENG',
    it: 'ITA'
};

const selectedLanguage = ref(store.state.locale);

// Watch per aggiornare `locale` di i18n e salvare la lingua nello store
watch(selectedLanguage, (newLang) => {
    locale.value = newLang; // aggiorna `locale` di i18n
    store.commit('setLocale', newLang); // salva nello store e nel localStorage se impostato nello store
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      "aria-label": "language",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedLanguage).value = $event)),
      class: "form-select"
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(languages, (lang, key) => {
        return _createElementVNode("option", {
          class: "bb-select-item",
          key: key,
          value: key
        }, _toDisplayString(lang), 9, _hoisted_2)
      }), 64))
    ], 512), [
      [_vModelSelect, selectedLanguage.value]
    ])
  ]))
}
}

})