<template>
    <NavbarComponent />
    <div class="container-xxl">
        <div class="row bb-backgroud bb-padding-top bb-padding">
            <div class="col-sm-12 mb-5">
                <h3 class="bb-txt-sec-name mb-4">{{ $t('COOKIE_1') }}</h3>
                <h4 class="bb-txt-sec-title mb-4">{{ $t('COOKIE_2') }}</h4>
            </div>
        </div>
        <div class="row bb-padding">
            <div class="col-sm-12 py-3 mb-5" style="background: white;">
                <p class="bb-info-txt mb-3" v-html="$t('COOKIE_3')"></p>
                <p class="bb-info-subtitle" v-html="$t('COOKIE_4')"></p>
                <p class="bb-info-txt" v-html="$t('COOKIE_5')"></p>

                <ul>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_6')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_7')"></p>
                    </li>
                </ul>

                <p class="bb-info-txt" v-html="$t('COOKIE_8')"></p>

                <ul class="mb-3">
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_9')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_10')"></p>
                    </li>
                </ul>

                <p class="bb-info-subtitle" v-html="$t('COOKIE_11')"></p>

                <p class="bb-info-txt mb-3" v-html="$t('COOKIE_12')"></p>

                <p class="bb-info-subtitle" v-html="$t('COOKIE_13')"></p>

                <ul>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_14')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_15')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_16')"></p>
                    </li>
                </ul>

                <div class="table-responsive-md mt-3 mb-3">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="">
                                <th scope="col" class="bb-info-subtitle col-2" v-html="$t('COOKIE_17')"></th>
                                <th scope="col" class="bb-info-subtitle col-2" v-html="$t('COOKIE_18')"></th>
                                <th scope="col" class="bb-info-subtitle col-2" v-html="$t('COOKIE_19')"></th>
                                <th scope="col" class="bb-info-subtitle col-2" v-html="$t('COOKIE_20')"></th>
                                <th scope="col" class="bb-info-subtitle col-4" v-html="$t('COOKIE_21')"></th>
                            </tr>
                        </thead>
                        <tbody class="bb-info-txt">
                            <tr>
                                <td v-html="$t('COOKIE_27')"></td>
                                <td v-html="$t('COOKIE_28')"></td>
                                <td v-html="$t('COOKIE_29')"></td>
                                <td v-html="$t('COOKIE_30')"></td>
                                <td v-html="$t('COOKIE_31')"></td>
                            </tr>
                            <tr>
                                <td v-html="$t('COOKIE_22')"></td>
                                <td v-html="$t('COOKIE_23')"></td>
                                <td v-html="$t('COOKIE_25')"></td>
                                <td v-html="$t('COOKIE_24')"></td>
                                <td v-html="$t('COOKIE_26')"></td>
                            </tr>
                            <tr>
                                <td v-html="$t('COOKIE_32')"></td>
                                <td v-html="$t('COOKIE_33')"></td>
                                <td v-html="$t('COOKIE_34')"></td>
                                <td v-html="$t('COOKIE_35')"></td>
                                <td v-html="$t('COOKIE_36')"></td>
                            </tr>
                            <tr >
                                <td v-html="$t('COOKIE_37')"></td>
                                <td v-html="$t('COOKIE_38')"></td>
                                <td v-html="$t('COOKIE_39')"></td>
                                <td v-html="$t('COOKIE_40')"></td>
                                <td v-html="$t('COOKIE_41')"></td>
                            </tr>
                            <tr >
                                <td v-html="$t('COOKIE_57')"></td>
                                <td v-html="$t('COOKIE_58')"></td>
                                <td v-html="$t('COOKIE_59')"></td>
                                <td v-html="$t('COOKIE_60')"></td>
                                <td v-html="$t('COOKIE_61')"></td>
                            </tr>
                            <tr >
                                <td v-html="$t('COOKIE_62')"></td>
                                <td v-html="$t('COOKIE_63')"></td>
                                <td v-html="$t('COOKIE_64')"></td>
                                <td v-html="$t('COOKIE_65')"></td>
                                <td v-html="$t('COOKIE_66')"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <p class="bb-info-subtitle" v-html="$t('COOKIE_42')"></p>
                <p class="bb-info-txt" v-html="$t('COOKIE_43')"></p>
                <ul>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_44', { link: '<a href=\'https://www.google.it/intl/it/policies/privacy/\' target=\'_blank\'>https://www.google.it/intl/it/policies/privacy/</a>' })"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_45')"></p>
                    </li>
                    <li>
                        <p class="bb-info-txt" v-html="$t('COOKIE_46')"></p>
                        <ul>
                            <li>
                                <p class="bb-info-txt" v-html="$t('COOKIE_47')"></p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p class="bb-info-txt mb-3" v-html="$t('COOKIE_48', { link: '<a href=\'https://www.youronlinechoices.com/it/\' target=\'_blank\'>www.youronlinechoices.com/it</a>' })"></p>
                <p class="bb-info-subtitle" v-html="$t('COOKIE_49')"></p>
                <p class="bb-info-txt" v-html="$t('COOKIE_50')"></p>

                <ul>
                    <li>
                        <p class="bb-info-txt">
                            <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank" v-html="$t('COOKIE_51')"></a>
                        </p>
                    </li>
                    <li>
                        <p class="bb-info-txt">
                            <a href="https://support.google.com/chrome/answer/95647" target="_blank" v-html="$t('COOKIE_52')"></a>
                        </p>
                    </li>
                    <li>
                        <p class="bb-info-txt">
                            <a href="https://support.microsoft.com/it-it/windows/gestire-i-cookie-in-microsoft-edge-visualizzare-consentire-bloccare-eliminare-e-usare-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" v-html="$t('COOKIE_53')"></a>
                        </p>
                    </li>
                    <li>
                        <p class="bb-info-txt">
                            <a href="https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent" target="_blank" v-html="$t('COOKIE_54')"></a>
                        </p>
                    </li>
                    <li>
                        <p class="bb-info-txt">
                            <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" v-html="$t('COOKIE_55')"></a>
                        </p>
                    </li>
                </ul>
                <hr >
                <p class="bb-info-subtitle" v-html="$t('COOKIE_56')"></p>
            </div>
        </div>
    </div>
<FooterComponent />

</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import NavbarComponent from "@/components/elements/NavbarComponent.vue";
import FooterComponent from "@/components/layout/FooterComponent.vue";
onMounted(() =>{
 window.scrollTo(0,0);

});

</script>

<style scoped lang="scss">

.bb-padding-top{
    padding-top: 10rem;
}

@media (min-width: 2800px) {

    .bb-padding-top{
        padding-top: 15rem;
    }
}

@media only screen and (min-width: 2300px) and (max-width:2799px){
    .bb-padding-top{
        padding-top: 13rem;
    }
}

</style>