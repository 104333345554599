<template>

      <nav class="navbar fixed-top navbar-expand-lg navbar-light bb-navbar">
        <div class="container-fluid mt-3 mb-3 p-0">
    
           <router-link class="navbar-brand bb-padding" :to="{ name: 'home' }" aria-label="B4B"> 
              <img  src="@/assets/images/icons/bb-logo.svg" alt="" class="bb-logo-header">
            </router-link>
            <button class="navbar-toggler custom-toggler bb-padding" type="button" data-bs-toggle="collapse" name="menu" data-bs-target="#navbarSupportedContent" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end bb-padding" id="navbarSupportedContent">
              <div class="order-1 order-md-2">
                <LanguageComponent />
              </div>
              <ul class="navbar-nav mb-2 mb-lg-0 self-align-end order-2 order-md-1">
                <li class="nav-item bb-nav-item ">
                    <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"  aria-current="page" href="#" @click.prevent="scrollToSection('who-we-are')"><span>{{ $t('NAVBAR_WHO_WE_ARE') }}</span></a>
                </li>
                <li class="nav-item bb-nav-item ">
                    <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" href="#" @click.prevent="scrollToSection('what-we-do')"><span>{{ $t('NAVBAR_WHAT_WE_DO') }}</span></a>
                </li>
                <li class="nav-item bb-nav-item ">
                    <router-link class="nav-link " :to="{ name: 'workWithUs' }"> <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">{{ $t('NAVBAR_WORK_WITH_US') }}</span></router-link>
                </li>
                <li class="nav-item bb-nav-item ">
                    <a class="nav-link" href="#" @click.prevent="scrollToSection('contacts')" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><span>{{ $t('NAVBAR_CONTACTS') }}</span></a>
                </li>
              </ul>
            </div>   
        </div>
      </nav>
</template>
  
<script setup lang="ts">
import { useRouter } from "vue-router";
import LanguageComponent from "../elements/LanguageComponent.vue";

const router = useRouter();

const scrollToSection = (sectionId: string) => {
  if(router.currentRoute.value.name !== 'home') {
          router.push({ name: 'home', query: { section: sectionId } });
  } else {
      const element = document.getElementById(sectionId);
      if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
      }
  }
};
</script>
  
<style scoped lang="scss">

@media only screen and (min-width:426px) and (max-width:991px) {
  .bb-ml{
    margin-left: 1rem; 
  }

}


@media only screen and (max-width: 425px) {
  .bb-ml{
    margin-left: 0rem; 
  }
}

</style>