import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-xxl" }
const _hoisted_2 = { class: "row bb-backgroud bb-padding-top bb-padding" }
const _hoisted_3 = { class: "col-sm-12 mb-5" }
const _hoisted_4 = { class: "bb-txt-sec-name mb-4" }
const _hoisted_5 = { class: "bb-txt-sec-title mb-4" }
const _hoisted_6 = { class: "row bb-padding" }
const _hoisted_7 = {
  class: "col-sm-12 py-3 mb-5",
  style: {"background":"white"}
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = {
  class: "bb-info-txt mb-3",
  type: "a"
}
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "bb-info-txt" }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = ["innerHTML"]

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import NavbarComponent from "@/components/elements/NavbarComponent.vue";
import FooterComponent from "@/components/layout/FooterComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TermsAndConditions',
  setup(__props) {

const router = useRouter();

onMounted(() =>{
 window.scrollTo(0,0);

});

function goToPersonalData(){
    router.push("data-protection-policy");
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavbarComponent),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('TC_1')), 1),
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('TC_2')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('TC_3')
          }, null, 8, _hoisted_8),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_4')
          }, null, 8, _hoisted_9),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('TC_5')
          }, null, 8, _hoisted_10),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_6')
          }, null, 8, _hoisted_11),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('TC_7')
              }, null, 8, _hoisted_12)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('TC_8')
              }, null, 8, _hoisted_13)
            ])
          ]),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_9')
          }, null, 8, _hoisted_14),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('TC_10')
          }, null, 8, _hoisted_15),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_11')
          }, null, 8, _hoisted_16),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('TC_12')
          }, null, 8, _hoisted_17),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_13')
          }, null, 8, _hoisted_18),
          _createElementVNode("p", {
            class: "bb-info-txt",
            innerHTML: _ctx.$t('TC_14')
          }, null, 8, _hoisted_19),
          _createElementVNode("ol", _hoisted_20, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('TC_15')
              }, null, 8, _hoisted_21)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('TC_16')
              }, null, 8, _hoisted_22)
            ])
          ]),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_17')
          }, null, 8, _hoisted_23),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('TC_18')
          }, null, 8, _hoisted_24),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_19')
          }, null, 8, _hoisted_25),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('TC_20')
              }, null, 8, _hoisted_26)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", _hoisted_27, [
                _createElementVNode("span", {
                  innerHTML: _ctx.$t('TC_21')
                }, null, 8, _hoisted_28),
                _createElementVNode("span", null, [
                  _createElementVNode("a", {
                    href: "",
                    innerHTML: _ctx.$t('TC_28'),
                    onClick: goToPersonalData
                  }, null, 8, _hoisted_29)
                ])
              ])
            ])
          ]),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_22')
          }, null, 8, _hoisted_30),
          _createElementVNode("p", {
            class: "bb-info-txt mb-3",
            innerHTML: _ctx.$t('TC_23')
          }, null, 8, _hoisted_31),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_24')
          }, null, 8, _hoisted_32),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('TC_25')
              }, null, 8, _hoisted_33)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", {
                class: "bb-info-txt",
                innerHTML: _ctx.$t('TC_26')
              }, null, 8, _hoisted_34)
            ])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("p", {
            class: "bb-info-subtitle",
            innerHTML: _ctx.$t('TC_27')
          }, null, 8, _hoisted_35)
        ])
      ])
    ]),
    _createVNode(FooterComponent)
  ], 64))
}
}

})