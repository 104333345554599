<template>
    <NavbarBlackshipComponent />
    <div class="container-xxl">
        <div class="row bb-backgroud bb-padding-top bb-padding">
            <div class="col-sm-12 mb-5">
                <h2 class="bb-txt-sec-name mb-4">{{ $t('TC_1') }}</h2>
                <h3 class="bb-txt-sec-title mb-4">{{ $t('TC_2') }}</h3>
            </div>
        </div>
        <div class="row bb-padding">
            <div class="col-sm-12 py-3 mb-5" style="background: white;">
                    <p class="bb-info-txt mb-3" v-html="$t('TC_3')"></p>
                    <p class="bb-info-subtitle" v-html="$t('TC_4')"></p>
                    <p class="bb-info-txt mb-3" v-html="$t('TC_5')"></p>
                    
                    <p class="bb-info-subtitle" v-html="$t('TC_6')"></p>
                    <ul>
                        <li>
                            <p class="bb-info-txt" v-html="$t('TC_7')"></p>
                        </li>
                        <li>
                            <p class="bb-info-txt" v-html="$t('TC_8')"></p>
                        </li>
                    </ul>
                    <p class="bb-info-subtitle" v-html="$t('TC_9')"></p>
                    <p class="bb-info-txt mb-3" v-html="$t('TC_10')"></p>
                    
                    <p class="bb-info-subtitle" v-html="$t('TC_11')"></p>
                    <p class="bb-info-txt mb-3" v-html="$t('TC_12')"></p>
                    
                    <p class="bb-info-subtitle" v-html="$t('TC_13')"></p>
                    <p class="bb-info-txt" v-html="$t('TC_14')"></p>
                    <ol class="bb-info-txt mb-3" type="a">
                        <li>
                            <p class="bb-info-txt" v-html="$t('TC_15')"></p>
                        </li>
                        <li>
                            <p class="bb-info-txt" v-html="$t('TC_16')"></p>
                        </li>
                    </ol>

                    <p class="bb-info-subtitle" v-html="$t('TC_17')"></p>
                    <p class="bb-info-txt mb-3" v-html="$t('TC_18')"></p>
                    
                    <p class="bb-info-subtitle" v-html="$t('TC_19')"></p>
                    <ul>
                        <li>
                            <p class="bb-info-txt" v-html="$t('TC_20')"></p>
                        </li>
                        <li>
                            <p class="bb-info-txt">
                                <span v-html="$t('TC_21')"></span>
                                <span><a href="" v-html="$t('TC_28')" @click="goToPersonalDataBl"></a></span>
                            </p>
                        </li>
                    </ul>

                    <p class="bb-info-subtitle" v-html="$t('TC_22')"></p>
                    <p class="bb-info-txt mb-3" v-html="$t('TC_23')"></p>


                    <p class="bb-info-subtitle" v-html="$t('TC_24')"></p>
                    <ul>
                        <li>
                            <p class="bb-info-txt" v-html="$t('TC_25')"></p>
                        </li>
                        <li>
                            <p class="bb-info-txt" v-html="$t('TC_26')"></p>
                        </li>
                    </ul>
                    <hr>
                    <p class="bb-info-subtitle" v-html="$t('TC_27')"></p>
            </div>
        </div>
    </div>
<FooterBlackshipComponent />

</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import NavbarBlackshipComponent from "@/components/elements/NavbarBlackshipComponent.vue";
import FooterBlackshipComponent from "@/components/layout/FooterBlackshipComponent.vue";

const router = useRouter();

onMounted(() =>{
 window.scrollTo(0,0);
});

function goToPersonalDataBl(){
    router.push("data-protection-policy-bl");
}

</script>

<style scoped lang="scss">
.bb-padding-top{
    padding-top: 10rem;
}

</style>